import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  Box,
  Paper,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  BottomNavigation,
  BottomNavigationAction,
  Snackbar,
  IconButton,
  Drawer,
  Link,
  Alert
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import GroupIcon from "@mui/icons-material/Group";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SearchIcon from "@mui/icons-material/Search";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import { useNavigate } from "react-router-dom";
import { db } from "./firebaseConfig";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import cities from "./cities.json";
import MenuIcon from "@mui/icons-material/Menu";
import { Helmet } from "react-helmet";
import footerImage from "./footer.png"

function CreateRide() {
  const [ride, setRide] = useState({
    fromCity: "",
    toCity: "",
    date: "",
    time: "",
    seatsAvailable: "",
    price: "",
    vehicle: "",
    description: "",
  });

  const [errors, setErrors] = useState({});
  const [navValue, setNavValue] = useState(2);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [filteredFromCities, setFilteredFromCities] = useState(cities);
  const [filteredToCities, setFilteredToCities] = useState(cities);
  const [showFromCityDropdown, setShowFromCityDropdown] = useState(false);
  const [showToCityDropdown, setShowToCityDropdown] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track form submission
  const navigate = useNavigate();

  const today = new Date().toISOString().split("T")[0];

  const validateFields = () => {
    let tempErrors = {};
    if (!ride.fromCity) tempErrors.fromCity = "From city is required";
    if (!ride.toCity) tempErrors.toCity = "To city is required";
    if (!ride.date) tempErrors.date = "Date is required";
    if (!ride.time) tempErrors.time = "Time is required";
    if (!ride.seatsAvailable) tempErrors.seatsAvailable = "Seats available is required";
    if (!ride.price) tempErrors.price = "Price is required";
    if (ride.price && ride.price > 1000) tempErrors.price = "Price cannot exceed 1000";
    if (!ride.vehicle) tempErrors.vehicle = "Vehicle is required";
    if (!ride.description) tempErrors.description = "Description is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (e) => {
    setRide({ ...ride, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });

    if (e.target.name === "fromCity") {
      setFilteredFromCities(
        cities.filter(city => city.toLowerCase().includes(e.target.value.toLowerCase()))
      );
    } else if (e.target.name === "toCity") {
      setFilteredToCities(
        cities.filter(city => city.toLowerCase().includes(e.target.value.toLowerCase()))
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) return;

    if (isSubmitting) return; // Prevent multiple submissions

    setIsSubmitting(true); // Set submitting state to true

    try {
      const user = getAuth().currentUser;
      if (!user) {
        throw new Error("User is not authenticated");
      }

      const userDocRef = doc(db, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (!userDocSnap.exists()) {
        throw new Error("User profile does not exist");
      }

      const userData = userDocSnap.data();
      const phoneNumber = userData.phoneNumber;

      // Check if phone number is set
      if (!phoneNumber) {
        setErrors({ phoneNumber: "Немате внесено телефонски број. Ве молиме одете на вашиот профил и внесете телефонски број." });
        setOpenSnackbar(true);  // Show error in snackbar
        setIsSubmitting(false); // Reset submitting state
        return;
      }

      const newDocId = uuidv4();

      await setDoc(doc(db, "offers", newDocId), {
        arrivalCity: ride.toCity,
        carModel: ride.vehicle,
        date: new Date(ride.date),
        departureCity: ride.fromCity,
        description: ride.description,
        phoneNumber: phoneNumber,
        price: parseFloat(ride.price),
        seats: parseInt(ride.seatsAvailable, 10),
        time: new Date(`${ride.date}T${ride.time}:00`),
        userId: user.uid,
      });

      setOpenSnackbar(true);
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } catch (e) {
      console.error("Error adding document: ", e);
    } finally {
      setIsSubmitting(false); // Reset submitting state
    }
  };

  const handleNavChange = (event, newValue) => {
    setNavValue(newValue);
    if (newValue === 0) {
      navigate("/");
    } else if (newValue === 1) {
      navigate("/my-rides");
    } else if (newValue === 2) {
      navigate("/create-ride");
    } else if (newValue === 3) {
      navigate("/profile");
    } else if (newValue === 4) {
      navigate("/packets-selection");
    }
  };

  return (
    <div style={{ backgroundColor: "#F8B400", minHeight: "100vh", display: 'flex', flexDirection: 'column' }}>
      <Helmet>
        <title>Нов Превоз | Upsy.mk</title>
        <meta name="description" content="Креирајте нов превоз на Upsy.mk и понудете патување за другите корисници. Внесете детали и објавете го огласот." />
      </Helmet>
      <AppBar position="fixed" sx={{ backgroundColor: "rgba(255, 255, 255, 0.5)", backdropFilter: "blur(10px)", paddingBottom: 1, paddingTop: 0.5 }}>
        <Toolbar>
        <Hidden smUp>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setDrawerOpen(true)}
              sx={{ color: "black" }} // Set icon color to black
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Typography variant="h6" sx={{ color: "black", fontFamily: "Outfit", fontWeight: "bold", flexGrow: 1 }}>
            Upsy.mk
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" sx={{backdropFilter:"blur(10px)"}} open={drawerOpen} onClose={() => setDrawerOpen(false)} >
        <List>
          <ListItem button onClick={() => { navigate("/about-us"); setDrawerOpen(false); }}>
            <ListItemText primary="За Нас" />
          </ListItem>
          <ListItem button onClick={() => { navigate("/terms-and-conditions"); setDrawerOpen(false); }}>
            <ListItemText primary="Правила и Услови" />
          </ListItem>
          <ListItem button onClick={() => { navigate("/privacy-policy"); setDrawerOpen(false); }}>
            <ListItemText primary="Политика на Приватност" />
          </ListItem>
        </List>
      </Drawer>
      <Container style={{ marginTop: 64, display: 'flex', padding: 0, justifyContent: 'center' }}>
        <Hidden smDown>
        <Box sx={{ width: 200, marginRight: 0.5, position: "fixed", marginRight: 130 }}>
            <Box sx={{ textAlign: 'center', padding: 2 }}>
              <Typography variant="h3" style={{ fontFamily: 'Outfit', fontWeight: 'bold' }}>
                Upsy.
              </Typography>
              <Typography variant="body2" style={{ fontFamily: 'Montserrat' }}>
                Каде денес?
              </Typography>
            </Box>
            <List component="nav" style={{ position: 'relative', top: 0 }}>
              <ListItem button selected={navValue === 0} onClick={() => handleNavChange(null, 0)}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Дома" />
              </ListItem>
              <ListItem button selected={navValue === 1} onClick={() => handleNavChange(null, 1)}>
                <ListItemIcon>
                  <DirectionsCarIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold', fontSize:'15px'}} primary="Мои Превози" />
              </ListItem>
              <ListItem button selected={navValue === 2} onClick={() => handleNavChange(null, 2)}>
                <ListItemIcon>
                  <PlaylistAddIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Нов Превоз" />
              </ListItem>
              <ListItem button selected={navValue === 3} onClick={() => handleNavChange(null, 3)}>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Профил" />
              </ListItem>
            </List>
          </Box>
        </Hidden>
        <Container maxWidth="md" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 35 }}>
          <Paper sx={{ p: 3, borderRadius: 3, border: '1px solid #ddd', maxWidth: 600, margin: '0 auto', '@media (max-width: 600px)': { margin: 2 } }}>
            <Typography variant="h4" component="h1" gutterBottom style={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
              Нов Превоз
            </Typography>
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField
                  InputLabelProps={{
                    sx: {
                      fontFamily: 'Montserrat',
                      fontWeight: 'bold',
                      fontSize: '15px'
                    }
                  }}
                  label="Од"
                  variant="outlined"
                  name="fromCity"
                  value={ride.fromCity}
                  onChange={handleChange}
                  error={!!errors.fromCity}
                  helperText={errors.fromCity}
                  onFocus={() => setShowFromCityDropdown(true)}
                  onBlur={() => setTimeout(() => setShowFromCityDropdown(false), 200)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ maxWidth: 600 }}
                />
                {showFromCityDropdown && (
                  <Box sx={{ maxHeight: 200, overflowY: 'auto', border: '1px solid #ddd', borderRadius: 2, marginBottom: 2 }}>
                    {filteredFromCities.map((city, index) => (
                      <MenuItem key={index} value={city} onClick={() => setRide({ ...ride, fromCity: city })}>
                        {city}
                      </MenuItem>
                    ))}
                  </Box>
                )}
                <TextField
                  InputLabelProps={{
                    sx: {
                      fontFamily: 'Montserrat',
                      fontWeight: 'bold',
                      fontSize: '15px'
                    }
                  }}
                  label="До"
                  variant="outlined"
                  name="toCity"
                  value={ride.toCity}
                  onChange={handleChange}
                  error={!!errors.toCity}
                  helperText={errors.toCity}
                  onFocus={() => setShowToCityDropdown(true)}
                  onBlur={() => setTimeout(() => setShowToCityDropdown(false), 200)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ maxWidth: 600 }}
                />
                {showToCityDropdown && (
                  <Box sx={{ maxHeight: 200, overflowY: 'auto', border: '1px solid #ddd', borderRadius: 2, marginBottom: 2 }}>
                    {filteredToCities.map((city, index) => (
                      <MenuItem key={index} value={city} onClick={() => setRide({ ...ride, toCity: city })}>
                        {city}
                      </MenuItem>
                    ))}
                  </Box>
                )}
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <TextField
                    label="Датум"
                    type="date"
                    variant="outlined"
                    name="date"
                    value={ride.date}
                    onChange={handleChange}
                    error={!!errors.date}
                    helperText={errors.date}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontFamily: 'Montserrat',
                        fontWeight: 'bold',
                        fontSize: '14px'
                      }
                    }}
                    inputProps={{ min: today }}
                    fullWidth
                  />
                  <TextField
                    label="Време"
                    type="time"
                    variant="outlined"
                    name="time"
                    value={ride.time}
                    onChange={handleChange}
                    error={!!errors.time}
                    helperText={errors.time}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontFamily: 'Montserrat',
                        fontWeight: 'bold',
                        fontSize: '14px'
                      }
                    }}
                    fullWidth
                  />
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <TextField
                    InputLabelProps={{
                      sx: {
                        fontFamily: 'Montserrat',
                        fontWeight: 'bold',
                        fontSize: '14px'
                      }
                    }}
                    label="Број на седишта"
                    variant="outlined"
                    name="seatsAvailable"
                    value={ride.seatsAvailable}
                    onChange={handleChange}
                    error={!!errors.seatsAvailable}
                    helperText={errors.seatsAvailable}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <GroupIcon />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                  <TextField
                    InputLabelProps={{
                      sx: {
                        fontFamily: 'Montserrat',
                        fontWeight: 'bold',
                        fontSize: '15px'
                      }
                    }}
                    label="Цена"
                    variant="outlined"
                    name="price"
                    value={ride.price}
                    onChange={handleChange}
                    error={!!errors.price}
                    helperText={errors.price}
                    inputProps={{ max: 1000 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <AttachMoneyIcon />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                </Box>
                <TextField
                  InputLabelProps={{
                    sx: {
                      fontFamily: 'Montserrat',
                      fontWeight: 'bold',
                      fontSize: '15px'
                    }
                  }}
                  label="Возило"
                  variant="outlined"
                  name="vehicle"
                  value={ride.vehicle}
                  onChange={handleChange}
                  error={!!errors.vehicle}
                  helperText={errors.vehicle}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <DriveEtaIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  InputLabelProps={{
                    sx: {
                      fontFamily: 'Montserrat',
                      fontWeight: 'bold',
                      fontSize: '14px'
                    }
                  }}
                  label="Дополнителен опис"
                  variant="outlined"
                  name="description"
                  value={ride.description}
                  onChange={handleChange}
                  error={!!errors.description}
                  helperText={errors.description}
                />
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  style={{ backgroundColor: "#F8B400", color: "#fff", borderRadius: 10, border: '1px solid black' }}
                  disabled={isSubmitting} // Disable button while submitting
                >
                  <Typography fontFamily={'Montserrat'} fontWeight={'bold'} color={'black'}>Објави</Typography>
                </Button>
              </Box>
            </form>
          </Paper>
        </Container>
      </Container>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={errors.phoneNumber ? "error" : "success"} sx={{ width: '100%' }}>
          {errors.phoneNumber ? errors.phoneNumber : "Ride successfully posted!"}
        </Alert>
      </Snackbar>
      <Hidden smUp>
        <Box sx={{ width: '100%', position: 'fixed', bottom: 0, backgroundColor: '#fff' }}>
          <BottomNavigation
            value={navValue}
            onChange={handleNavChange}
            showLabels
          >
             <BottomNavigationAction label="Дома" icon={<HomeIcon />} />
            <BottomNavigationAction label="Мои Превози" icon={<DirectionsCarIcon />} />
            <BottomNavigationAction label="Нов Превоз" icon={<PlaylistAddIcon />} />
            <BottomNavigationAction label="Профил" icon={<AccountCircleIcon />} />
            {/* <BottomNavigationAction label="Пакети" icon={<AddToPhotosIcon />} /> */}
          </BottomNavigation>
        </Box>
      </Hidden>
      <Hidden smDown>
      <Box sx={{ width: '100%', mt: 5 }}>
          <img src={footerImage} alt="Footer" style={{ width: '100%', position: 'absolute', bottom: '0', right:'0', left:"0" }} />
          <Box sx={{ position: 'absolute', bottom: '20px', left: '10px', display: 'flex', gap: '20px' }}>
            <Link href="/about-us" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none', paddingTop: '12px' }}>За нас</Link>
            <Link href="/terms-and-conditions" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none', paddingTop: '12px' }}>Правила и Услови</Link>
            <Link href="/privacy-policy" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none', paddingTop: '12px' }}>Политика на Приватност</Link>
            <Link href="/contact" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none', paddingTop: '12px' }}>Контакт</Link>
          </Box>
        </Box>
      </Hidden>
    </div>
  );
}

export default CreateRide;
