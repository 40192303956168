import React, { useState, useEffect } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Grid,
  MenuItem,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
  Hidden,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Drawer,
  Link,
} from "@mui/material";
import { AccessTime, Favorite as FavoriteIcon, FavoriteBorder as FavoriteBorderIcon } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import HomeIcon from "@mui/icons-material/Home";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ListIcon from "@mui/icons-material/List";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PhoneIcon from "@mui/icons-material/Phone";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import GroupIcon from "@mui/icons-material/Group";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MenuIcon from "@mui/icons-material/Menu";
import { getAuth } from "firebase/auth";
import { db } from "./firebaseConfig";
import { collection, query, where, getDocs, doc, getDoc, Timestamp, setDoc, orderBy } from "firebase/firestore";
import cities from "./cities.json";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import footerImage from './footer.png';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import MessageIcon from '@mui/icons-material/Message';
import appStoreImage from './appstore.png';
import playStoreImage from './playstore.png';
import { Helmet } from "react-helmet";




const fixedRoutes = [
  { from: "Скопје", to: "Охрид" },
  { from: "Скопје", to: "Куманово" },
  { from: "Битола", to: "Скопје" },
  { from: "Кавадарци", to: "Скопје" },
  { from: "Прилеп", to: "Скопје" },
];

const days = ["Нед", "Пон", "Вто", "Сре", "Чет", "Пет", "Саб"];

const slogans = [
  "Your journey starts here!",
  "Travel with comfort.",
  "Rides made easy.",
  "Find your ride.",
  "Safe and reliable trips."
];

function MainScreen() {
  const [search, setSearch] = useState({
    fromCity: "",
    toCity: "",
    date: "",
  });
  const [rides, setRides] = useState([]);
  const [navValue, setNavValue] = useState(0);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [open, setOpen] = useState(false);
  const [offerCounts, setOfferCounts] = useState({});
  const [fixedRoutesState, setFixedRoutesState] = useState(fixedRoutes);
  const [loading, setLoading] = useState(false);
  const [selectedSlogan, setSelectedSlogan] = useState("");
  const [filteredFromCities, setFilteredFromCities] = useState(cities);
  const [filteredToCities, setFilteredToCities] = useState(cities);
  const [showFromCityDropdown, setShowFromCityDropdown] = useState(false);
  const [showToCityDropdown, setShowToCityDropdown] = useState(false);
  const [errors, setErrors] = useState({});
  const [searched, setSearched] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [favorites, setFavorites] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    countOffers();
    setSelectedSlogan(slogans[Math.floor(Math.random() * slogans.length)]);
  }, [search, fixedRoutesState]);

  useEffect(() => {
    const fetchFavorites = async () => {
      const user = getAuth().currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setFavorites(userDocSnap.data().favorites || []);
        }
      }
    };

    fetchFavorites();
  }, []);

  const handleChange = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });

    if (e.target.name === "fromCity") {
      setFilteredFromCities(
        cities.filter(city => city.toLowerCase().includes(e.target.value.toLowerCase()))
      );
    } else if (e.target.name === "toCity") {
      setFilteredToCities(
        cities.filter(city => city.toLowerCase().includes(e.target.value.toLowerCase()))
      );
    }
  };

  const handleSearch = async () => {
    if (!search.date) {
      alert("Please select a date to search for an offer.");
      return;
    }
    setSearched(true);
    const ridesRef = collection(db, "offers");
    let q = query(ridesRef);

    if (search.fromCity) {
      q = query(q, where("departureCity", "==", search.fromCity));
    }
    if (search.toCity) {
      q = query(q, where("arrivalCity", "==", search.toCity));
    }
    if (search.date) {
      const searchDate = new Date(search.date);
      const startOfDay = new Date(
        searchDate.getFullYear(),
        searchDate.getMonth(),
        searchDate.getDate(),
        0, 0, 0
      );
      const endOfDay = new Date(
        searchDate.getFullYear(),
        searchDate.getMonth(),
        searchDate.getDate(),
        23, 59, 59
      );
      q = query(q, where("date", ">=", Timestamp.fromDate(startOfDay)), where("date", "<=", Timestamp.fromDate(endOfDay)));
    }

    const querySnapshot = await getDocs(q);
    const ridePromises = querySnapshot.docs.map(async (rideDoc) => {
      const rideData = rideDoc.data();
      const userDoc = await getDoc(doc(db, "users", rideData.userId));
      if (userDoc.exists()) {
        rideData.driverName = userDoc.data().name;
      } else {
        rideData.driverName = "Unknown";
      }

      rideData.date = rideData.date.toDate ? rideData.date.toDate() : new Date(rideData.date);
      rideData.time = rideData.time.toDate ? rideData.time.toDate() : new Date(rideData.time);

      rideData.id = rideDoc.id;

      return rideData;
    });

    const searchResults = await Promise.all(ridePromises);
    const groupedResults = groupRidesByRoute(searchResults);
    setRides(groupedResults);
  };

  const groupRidesByRoute = (rides) => {
    const grouped = {};
    rides.forEach((ride) => {
      const route = `${ride.departureCity} > ${ride.arrivalCity}`;
      if (!grouped[route]) {
        grouped[route] = [];
      }
      grouped[route].push(ride);
    });
    return grouped;
  };

  const handleRouteSearch = async (fromCity, toCity, dateOffset) => {
    const searchDate = new Date();
    searchDate.setDate(searchDate.getDate() + dateOffset);
    
    setSearch({
      fromCity,
      toCity,
      date: searchDate.toISOString().split("T")[0], // Format date as YYYY-MM-DD
    });
  
    const ridesRef = collection(db, "offers");
    const startOfDay = new Date(searchDate.getFullYear(), searchDate.getMonth(), searchDate.getDate(), 0, 0, 0);
    const endOfDay = new Date(searchDate.getFullYear(), searchDate.getMonth(), searchDate.getDate(), 23, 59, 59);
  
    let q = query(
      ridesRef,
      where("departureCity", "==", fromCity),
      where("arrivalCity", "==", toCity),
      where("date", ">=", Timestamp.fromDate(startOfDay)),
      where("date", "<=", Timestamp.fromDate(endOfDay))
    );
  
    const querySnapshot = await getDocs(q);
    const ridePromises = querySnapshot.docs.map(async (rideDoc) => {
      const rideData = rideDoc.data();
      const userDoc = await getDoc(doc(db, "users", rideData.userId));
      if (userDoc.exists()) {
        rideData.driverName = userDoc.data().name;
      } else {
        rideData.driverName = "Unknown";
      }
  
      rideData.date = rideData.date.toDate ? rideData.date.toDate() : new Date(rideData.date);
      rideData.time = rideData.time.toDate ? rideData.time.toDate() : new Date(rideData.time);
  
      rideData.id = rideDoc.id;
  
      return rideData;
    });
  
    const searchResults = await Promise.all(ridePromises);
    const groupedResults = groupRidesByRoute(searchResults);
    setRides(groupedResults);
    setSearched(true); 
  };
  

  const countOffers = async () => {
    setLoading(true);
    const counts = {};
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const dayAfterTomorrow = new Date(today);
    dayAfterTomorrow.setDate(today.getDate() + 2);

    const ridesRef = collection(db, "offers");

    for (const route of fixedRoutesState) {
      const q = query(ridesRef, where("departureCity", "==", route.from), where("arrivalCity", "==", route.to));
      const querySnapshot = await getDocs(q);

      let todayCount = 0;
      let tomorrowCount = 0;
      let dayAfterTomorrowCount = 0;

      querySnapshot.docs.forEach((doc) => {
        const data = doc.data();
        const date = data.date.toDate ? data.date.toDate() : new Date(data.date);

        if (date.toDateString() === today.toDateString()) {
          todayCount++;
        } else if (date.toDateString() === tomorrow.toDateString()) {
          tomorrowCount++;
        } else if (date.toDateString() === dayAfterTomorrow.toDateString()) {
          dayAfterTomorrowCount++;
        }
      });

      counts[`${route.from}-${route.to}`] = {
        today: todayCount,
        tomorrow: tomorrowCount,
        dayAfterTomorrow: dayAfterTomorrowCount,
      };
    }

    setOfferCounts(counts);
    setLoading(false);
  };

  const handleSwap = () => {
    setSearch((prevState) => ({
      ...prevState,
      fromCity: prevState.toCity,
      toCity: prevState.fromCity,
    }));
  };

  const handleRouteSwap = (index) => {
    setLoading(true);
    setFixedRoutesState((prevState) =>
      prevState.map((route, i) =>
        i === index ? { from: route.to, to: route.from } : route
      )
    );
    setLoading(false);
  };

  const handleNavChange = (event, newValue) => {
    setNavValue(newValue);
    if (newValue === 0) {
      navigate("/");
    } else if (newValue === 1) {
      navigate("/my-rides");
    } else if (newValue === 2) {
      navigate("/create-ride");
    } else if (newValue === 3) {
      navigate("/profile");
    } else if (newValue === 4) {
      navigate("/contact");
    }
  };

  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
      },
    },
  };

  const handleCardClick = (offer) => {
    setSelectedOffer(offer);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddToFavorites = async (ride, event) => {
    event.stopPropagation();
    const user = getAuth().currentUser;
    if (!user) {
      alert("You need to be logged in to add favorites");
      return;
    }

    const userDocRef = doc(db, "users", user.uid);
    const userDocSnap = await getDoc(userDocRef);

    if (!userDocSnap.exists()) {
      alert("User document does not exist");
      return;
    }

    let userFavorites = userDocSnap.data().favorites || [];

    if (!userFavorites.includes(ride.id)) {
      userFavorites.push(ride.id);

      await setDoc(userDocRef, { favorites: userFavorites }, { merge: true });

      setSnackbarMessage("Ride added to favorites");
      setFavorites(userFavorites);
    } else {
      setSnackbarMessage("Ride is already in favorites");
    }

    setSnackbarOpen(true);
  };

  const handleWheneverSearch = async () => {
    if (!search.fromCity || !search.toCity) {
      alert("Ве молиме внесете ги од и до градовите.");
      return;
    }
  
    setSearched(true);
    setLoading(true);
  
    const ridesRef = collection(db, "offers");
    const today = new Date(); // Get today's date
  
    // Convert today's date to UTC and ensure time is set to start of the day
    const startOfDayUTC = new Date(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getUTCDate(),
      0, 0, 0
    );
  
    try {
      console.log("Searching for rides from today onwards...");
      console.log("Search parameters:", search.fromCity, search.toCity, startOfDayUTC);
  
      // Firestore query with Timestamp conversion and sorting by date + time in ascending order
      const q = query(
        ridesRef,
        where("departureCity", "==", search.fromCity),
        where("arrivalCity", "==", search.toCity),
        where("date", ">=", Timestamp.fromDate(startOfDayUTC)), // Query rides from the start of today onwards in UTC
        orderBy("date", "asc"), // Order by date first
        orderBy("time", "asc")  // Then order by time to ensure earliest time first
      );
  
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.empty) {
        console.log("No rides found."); // Log if no results found
        setRides([]); // Set an empty rides array if no results found
        setLoading(false);
        return;
      }
  
      console.log("Rides found:", querySnapshot.docs.length); // Check how many documents are returned
  
      const ridePromises = querySnapshot.docs.map(async (rideDoc) => {
        const rideData = rideDoc.data();
        const userDoc = await getDoc(doc(db, "users", rideData.userId));
  
        if (userDoc.exists()) {
          rideData.driverName = userDoc.data().name;
        } else {
          rideData.driverName = "Unknown";
        }
  
        // Ensure date and time are properly converted
        rideData.date = rideData.date.toDate ? rideData.date.toDate() : new Date(rideData.date);
        rideData.time = rideData.time.toDate ? rideData.time.toDate() : new Date(rideData.time);
        rideData.id = rideDoc.id;
  
       
  
        return rideData;
      });
  
      const searchResults = await Promise.all(ridePromises);
      const groupedResults = groupRidesByRoute(searchResults);
  
      setRides(groupedResults);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching rides:", error); // Log errors
      setLoading(false);
    }
  };
  

 const renderFixedRoutes = () => {
  if (search.fromCity || search.toCity || search.date) return null;

  return fixedRoutesState.map((route, index) => {
    const routeKey = `${route.from}-${route.to}`;
    const counts = offerCounts[routeKey] || { today: 0, tomorrow: 0, dayAfterTomorrow: 0 };

    return (
      <Paper key={index} sx={{ p: 2, borderRadius: 3, border: '1px solid #ddd', maxWidth: 600, margin: '20px auto', backgroundColor: '#E0E0E0', '@media (max-width: 600px)': { mr:2, ml:2 } }}>
        <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
          {route.from} {">"} {route.to}
          <IconButton onClick={() => handleRouteSwap(index)} style={{ float: 'right' }}>
            <CompareArrowsIcon />
          </IconButton>
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={4} onClick={() => handleRouteSearch(route.from, route.to, 0)}>
            {loading ? <CircularProgress /> : <Typography sx={{cursor: 'pointer'}} variant="h4" align="center" fontFamily={'Montserrat'} fontWeight={'bold'} color={'darkgrey'}>{counts.today}</Typography>}
            <Typography variant="body2" align="center">{days[new Date().getDay()]}</Typography>
          </Grid>
          <Grid item xs={4} onClick={() => handleRouteSearch(route.from, route.to, 1)}>
            {loading ? <CircularProgress /> : <Typography sx={{cursor: 'pointer'}} variant="h4" align="center" fontFamily={'Montserrat'} fontWeight={'bold'} color={'darkgrey'}>{counts.tomorrow}</Typography>}
            <Typography variant="body2" align="center">{days[(new Date().getDay() + 1) % 7]}</Typography>
          </Grid>
          <Grid item xs={4} onClick={() => handleRouteSearch(route.from, route.to, 2)}>
            {loading ? <CircularProgress /> : <Typography sx={{cursor: 'pointer'}} variant="h4" align="center" fontFamily={'Montserrat'} fontWeight={'bold'} color={'darkgrey'}>{counts.dayAfterTomorrow}</Typography>}
            <Typography variant="body2" align="center">{days[(new Date().getDay() + 2) % 7]}</Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  });
};


const renderOfferDetails = (offer) => {
  const user = getAuth().currentUser;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography variant="h6" sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
          {days[offer.date.getDay()]}, {offer.date.getDate()}.{offer.date.getMonth() + 1}.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body1" sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
              Од:
            </Typography>
            <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
              {offer.departureCity}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
              До:
            </Typography>
            <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
              {offer.arrivalCity}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
              Дата:
            </Typography>
            <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
              {offer.date.toDateString()}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
              Време:
            </Typography>
            <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
              {offer.time.getHours().toString().padStart(2, '0')}:{offer.time.getMinutes().toString().padStart(2, '0')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
              Возач:
            </Typography>
            <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
              {offer.driverName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
              Цена:
            </Typography>
            <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
              {offer.price} ден
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
              Автомобил:
            </Typography>
            <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
              {offer.carModel}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
              Слободни места:
            </Typography>
            <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
              {offer.seats}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
              Белешки:
            </Typography>
            <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
              {offer.description || "/"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
              Телефон:
            </Typography>
            {user ? (
              <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
                {offer.phoneNumber}
              </Typography>
            ) : (
              <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
                *Најавете се за да го видете телефонскиот број{" "}
                <Link href="/login" sx={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>
                  Најавете се
                </Link>
              </Typography>
            )}
            {user && (
              <Hidden smUp>
                <Button variant="contained" color="primary" startIcon={<PhoneIcon />} href={`tel:${offer.phoneNumber}`} sx={{ mt: 1 }}>
                  Јави се
                </Button>
                <Button variant="contained" color="primary" startIcon={<MessageIcon />} href={`sms:${offer.phoneNumber}`} sx={{ mt: 1, ml: 1 }}>
                  Порака
                </Button>
              </Hidden>
            )}
          </Grid>
          
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Затвори
        </Button>
      </DialogActions>
    </Dialog>
  );
};


  const isFavorite = (rideId) => favorites.includes(rideId);

  return (
<div style={{ backgroundColor: "#F8B400", minHeight: "100vh", position: 'relative', paddingBottom: '50px', paddingTop: 4 }}>      <AppBar position="fixed" sx={{ backgroundColor: "rgba(255, 255, 255, 0.5)", backdropFilter: "blur(10px)" , paddingBottom: 1, paddingTop:0.5}}>
<Helmet>
        <title>Дома | Upsy.mk</title>
        <meta name="description" content="Добредојдовте на Upsy.mk, каде што можете да најдете и креирате превози. Започнете со пребарување денес!" />
      </Helmet>
        <Toolbar>
          <Hidden smUp>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setDrawerOpen(true)}
              sx={{ color: "black" }} // Set icon color to black
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Typography
            variant="h6"
            component={RouterLink}
            to="/"
            sx={{ color: "black", fontFamily: "Outfit", fontWeight: "bold", flexGrow: 1, textDecoration: "none" }}
          >
            Upsy.mk
          </Typography>
          <Hidden smDown>
            <Typography variant="body1" sx={{ color: "black", fontFamily: "Outfit", fontWeight: "bold" }}>
              {selectedSlogan}
            </Typography>
          </Hidden>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" sx={{backdropFilter:"blur(10px)"}} open={drawerOpen} onClose={() => setDrawerOpen(false)}>
  <List>
    <ListItem button onClick={() => { navigate("/about-us"); setDrawerOpen(false); }}>
      <ListItemText primary="За нас" />
    </ListItem>
    <ListItem button onClick={() => { navigate("/terms-and-conditions"); setDrawerOpen(false); }}>
      <ListItemText primary="Правила и Услови" />
    </ListItem>
    <ListItem button onClick={() => { navigate("/privacy-policy"); setDrawerOpen(false); }}>
      <ListItemText primary="Политика на Приватност" />
    </ListItem>
  </List>

  {/* Add Play Store and App Store images */}
  <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, p: 1,pt:73 }}>
    <Link
      href="https://apps.apple.com/app/idXXXXXXXX"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={appStoreImage}
        alt="App Store"
        style={{ width: '120px', borderRadius: '10px' }}
      />
    </Link>
    <Link
      href="https://play.google.com/store/apps/details?id=XXXXXXXX"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={playStoreImage}
        alt="Play Store"
        style={{ width: '120px', borderRadius: '10px' }}
      />
    </Link>
  </Box>
</Drawer>

      <Container style={{ marginTop: 64, display: 'flex', padding: 0, justifyContent: 'center', backgroundColor: "#F8B400" }}>
      <Hidden smDown>
          <Box sx={{ width: 200, marginRight: 0.5, position: "fixed", marginRight: 130 }}>
            <Box sx={{ textAlign: 'center', padding: 2 }}>
              <Typography variant="h3" style={{ fontFamily: 'Outfit', fontWeight: 'bold' }}>
                Upsy.
              </Typography>
              <Typography variant="body2" style={{ fontFamily: 'Montserrat' }}>
                Каде денес?
              </Typography>
            </Box>
            <List component="nav" style={{ position: 'relative', top: 0 }}>
              <ListItem button selected={navValue === 0} onClick={() => handleNavChange(null, 0)}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Дома" />
              </ListItem>
              <ListItem button selected={navValue === 1} onClick={() => handleNavChange(null, 1)}>
                <ListItemIcon>
                  <DirectionsCarIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold', fontSize:'15px'}} primary="Мои Превози" />
              </ListItem>
              <ListItem button selected={navValue === 2} onClick={() => handleNavChange(null, 2)}>
                <ListItemIcon>
                  <PlaylistAddIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Нов Превоз" />
              </ListItem>
              <ListItem button selected={navValue === 3} onClick={() => handleNavChange(null, 3)}>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Профил" />
              </ListItem>
              {/* <ListItem button selected={navValue === 4} onClick={() => handleNavChange(null, 4)}>
                <ListItemIcon>
                  <AddToPhotosIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Пакети" />
              </ListItem> */}
            </List>
          </Box>
        </Hidden>
        <Container maxWidth="md" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 35 }}>
        <Paper sx={{ p: 3, borderRadius: 3, border: '1px solid #ddd', maxWidth: 600, margin: '0 auto', '@media (max-width: 600px)': { margin: 2 } }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ position: "relative" }}>
                <TextField
                InputLabelProps={{
                  sx: {
                   fontFamily:'Montserrat',
                    fontWeight:'bold',
                    fontSize:'15px'
                  }
                }}
                  label="Од"
                  fullWidth
                  variant="outlined"
                  name="fromCity"
                  value={search.fromCity}
                  onChange={handleChange}
                  onFocus={() => setShowFromCityDropdown(true)}
                  onBlur={() => setTimeout(() => setShowFromCityDropdown(false), 200)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ maxWidth: 600 }}
                />
                {showFromCityDropdown && (
                  <Box sx={{ maxHeight: 200, overflowY: 'auto', border: '1px solid #ddd', borderRadius: 2, marginBottom: 2 }}>
                    {filteredFromCities.map((city, index) => (
                      <MenuItem key={index} value={city} onClick={() => setSearch({ ...search, fromCity: city })}>
                        {city}
                      </MenuItem>
                    ))}
                  </Box>
                )}
                <IconButton
                  color="primary"
                  onClick={handleSwap}
                  style={{
                    position: "absolute",
                    color: 'black',
                    top: "113%",
                    right: "120px",
                    transform: "translateY(-50%)",
                    zIndex: 10,
                    backgroundColor: "#F8B400",
                    borderRadius: "50%",
                    width: 50,
                    height: 50,
                  }}
                >
                  <SwapVertIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <TextField
                InputLabelProps={{
                  sx: {
                   fontFamily:'Montserrat',
                    fontWeight:'bold',
                    fontSize:'15px'
                  }
                }}
                  label="До"
                  variant="outlined"
                  name="toCity"
                  fullWidth
                  value={search.toCity}
                  onChange={handleChange}
                  onFocus={() => setShowToCityDropdown(true)}
                  onBlur={() => setTimeout(() => setShowToCityDropdown(false), 200)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ maxWidth: 600 }}
                />
                {showToCityDropdown && (
                  <Box sx={{ maxHeight: 200, overflowY: 'auto', border: '1px solid #ddd', borderRadius: 2, marginBottom: 2 }}>
                    {filteredToCities.map((city, index) => (
                      <MenuItem key={index} value={city} onClick={() => setSearch({ ...search, toCity: city })}>
                        {city}
                      </MenuItem>
                    ))}
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} container spacing={2}>
  {/* Date field */}
  <Grid item xs={6}>
    <TextField
     InputLabelProps={{
      shrink: true,
      style: { // Use the 'style' property to apply CSS directly to the label
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        fontSize: '14px'
      }
    }}
      label="Датум"
      type="date"
      variant="outlined"
      fullWidth
      margin="normal"
      name="date"
      value={search.date}
      onChange={handleChange}
      
      InputProps={{
        inputProps: { min: new Date().toISOString().split("T")[0] },
      }}
      sx={{ maxWidth: 600 }}
    />
  </Grid>

  {/* "Било кога" Button */}
  <Grid item xs={6} display="flex" justifyContent="center" alignItems="center">
    <Button
      variant="contained"
      color="primary"
      fullWidth
      onClick={handleWheneverSearch}
      style={{ backgroundColor: "#F8B400", borderRadius: 6, border: '1px solid black', height: '56px' }} // Set height to match the date field
      sx={{ maxWidth: 600 }}
    >
      <Typography fontFamily={'Montserrat'} fontWeight={'bold'} color={'black'}>
        Било кога
      </Typography>
    </Button>
  </Grid>
</Grid>

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleSearch}
                  style={{ backgroundColor: "#F8B400", borderRadius: 6, border: '1px solid black' }}
                  sx={{ maxWidth: 600 }}
                >
                  <Typography fontFamily={'Montserrat'} fontWeight={'bold'} color={'black'}>Барај</Typography>
                </Button>
              </Grid>
            </Grid>
          </Paper>

          {renderFixedRoutes()}

          <Paper sx={{ p: 3, boxShadow: 'none', backgroundColor: 'transparent', borderRadius: 5, maxWidth: 600, margin: '20px auto', '@media (max-width: 600px)': { margin: 2 } }}>
            <Grid container spacing={2} style={{ maxWidth: 600 }}>
              {Object.keys(rides).length > 0 ? (
                Object.entries(rides).map(([route, ridesList], index) => (
                  <Grid item xs={12} key={index}>
                    <Paper sx={{ p: 2, borderRadius: 5, border: '1px solid #ddd', margin: '20px auto', backgroundColor: '#E0E0E0' }}>
                      <Typography variant="h6" sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
                        {route.split(' > ')[0]} <ArrowForwardIosIcon sx={{ fontSize: 'inherit' }} /> {route.split(' > ')[1]} <span style={{ float: "right", fontWeight: "bold" }}>{ridesList.length}</span>
                      </Typography>
                      {ridesList.map((ride, idx) => (
  <ListItem key={idx} button onClick={() => handleCardClick(ride)}>
    <ListItemText 
      primary={
        <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: 'Montserrat' }}>
          {`${ride.time.getHours().toString().padStart(2, '0')}:${ride.time.getMinutes().toString().padStart(2, '0')} ${ride.date.getDate()}.${ride.date.getMonth() + 1}.${ride.date.getFullYear()}`}
        </Typography>
      }
      secondary={
        <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
          {ride.driverName}
        </Typography>
      }
    />
    <Typography variant="body2" sx={{ fontWeight: 'bold', fontFamily: 'Montserrat', marginLeft: 'auto' }}>
      {ride.price} ден.
    </Typography>
    <IconButton onClick={(event) => handleAddToFavorites(ride, event)}>
      {isFavorite(ride.id) ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon />}
    </IconButton>
  </ListItem>
))}




                    </Paper>
                  </Grid>
                ))
              ) : (
                searched && (
                <Grid item xs={12}>
                  <Typography variant="h6" align="center">
                    Нема превози
                  </Typography>
                </Grid>
              )
              )}
            </Grid>
          </Paper>
        </Container>
      </Container>
      <Hidden smUp>
        <Box sx={{ width: '100%', position: 'fixed', bottom: 0, backgroundColor: '#fff' }}>
          <BottomNavigation
            value={navValue}
            onChange={handleNavChange}
            showLabels
          >
            <BottomNavigationAction label="Дома" icon={<HomeIcon />} />
            <BottomNavigationAction label="Мои Превози" icon={<DirectionsCarIcon />} />
            <BottomNavigationAction label="Нов Превоз" icon={<PlaylistAddIcon />} />
            <BottomNavigationAction label="Профил" icon={<AccountCircleIcon />} />
            {/* <BottomNavigationAction label="Пакети" icon={<AddToPhotosIcon />} /> */}
          </BottomNavigation>
        </Box>
      </Hidden>
      {selectedOffer && renderOfferDetails(selectedOffer)}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
       <Hidden smDown>
       <Box sx={{ width: '100%', mt: 5 }}>
          <img src={footerImage} alt="Footer" style={{ width: '100%', position: 'absolute', bottom: '0', right:'0', left:"0" }} />
          <Box sx={{ position: 'absolute', bottom: '5px', left: '10px', display: 'flex', gap: '20px' }}>
            <Link href="/about-us" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none', paddingTop: '12px' }}>За нас</Link>
            <Link href="/terms-and-conditions" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none', paddingTop: '12px' }}>Правила и Услови</Link>
            <Link href="/privacy-policy" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none', paddingTop: '12px' }}>Политика на Приватност</Link>
            <Link href="/contact" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none', paddingTop: '12px' }}>Контакт</Link>
            <Link
                href="https://apps.apple.com/app/idXXXXXXXX"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={appStoreImage}
                  alt="App Store"
                  style={{ width: '120px', borderRadius: '10px' }}
                />
              </Link>
              <Link
                href="https://play.google.com/store/apps/details?id=XXXXXXXX"
                target="_blank"
                rel="noopener noreferrer"
                
              >
                <img
                  src={playStoreImage}
                  alt="Play Store"
                  style={{ width: '120px', borderRadius: '10px' }}
                />
              </Link>
              
          </Box>
        </Box>

      </Hidden>
    </div>
  );
}

export default MainScreen;
