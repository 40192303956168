import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  IconButton,
  Box,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Link,
  TextField,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import emailjs from "emailjs-com";
import footerImage from './footer.png';
import appStoreImage from './appstore.png';
import playStoreImage from './playstore.png';
import { Helmet } from "react-helmet";

function Contact() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [navValue, setNavValue] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
    phoneNumber: "",
    description: "",
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const navigate = useNavigate();

  const handleNavChange = (event, newValue) => {
    setNavValue(newValue);
    if (newValue === 0) {
      navigate("/");
    } else if (newValue === 1) {
      navigate("/my-rides");
    } else if (newValue === 2) {
      navigate("/create-ride");
    } else if (newValue === 3) {
      navigate("/profile");
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSendEmail = (e) => {
    e.preventDefault();

    // Basic form validation
    if (!formData.name || !formData.email || !formData.phoneNumber || !formData.description) {
      setSnackbarMessage("Сите полиња се задолжителни.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    emailjs.send(
      "service_bmwovxo", // Replace with your EmailJS service ID
      "template_oyn0s3b", // Replace with your EmailJS template ID
      {
        from_name: formData.name,
        from_surname: formData.surname,
        from_phone: formData.phoneNumber,
        message: formData.description,
        to_name: "Gjoko",
        reply_to: formData.email,
      },
      "xnrSJLP-7DHfAlns9" // Replace with your EmailJS public key
    ).then(
      (result) => {
        setSnackbarMessage("Пораката е успешно испратена.");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setFormData({
          name: "",
          surname: "",
          email: "",
          phoneNumber: "",
          description: "",
        });
      },
      (error) => {
        setSnackbarMessage("Грешка при испраќање на пораката. Обидете се повторно.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    );
  };

  return (
    <div style={{ backgroundColor: "#F8B400", minHeight: "100vh", position: "relative" }}>
      <Helmet>
        <title>Контакт | Upsy.mk</title>
        <meta name="description" content="Контактирајте нѐ на Upsy.mk. Испратете порака или прашање и ние ќе ви одговориме во најкус можен рок." />
      </Helmet>
      <AppBar position="fixed" sx={{ backgroundColor: "rgba(255, 255, 255, 0.5)", backdropFilter: "blur(10px)", paddingBottom: 1, paddingTop: 0.5 }}>
        <Toolbar>
          <Hidden smUp>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setDrawerOpen(true)}
              sx={{ color: "black" }}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Typography variant="h6" sx={{ color: "black", fontFamily: "Outfit", fontWeight: "bold", flexGrow: 1 }}>
            Upsy.mk
          </Typography>
        </Toolbar>
      </AppBar>

      <Drawer anchor="left" sx={{ backdropFilter: "blur(10px)" }} open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <List>
          <ListItem button onClick={() => { navigate("/about-us"); setDrawerOpen(false); }}>
            <ListItemText primary="За нас" />
          </ListItem>
          <ListItem button onClick={() => { navigate("/terms-and-conditions"); setDrawerOpen(false); }}>
            <ListItemText primary="Правила и Услови" />
          </ListItem>
          <ListItem button onClick={() => { navigate("/privacy-policy"); setDrawerOpen(false); }}>
            <ListItemText primary="Политика на Приватност" />
          </ListItem>
        </List>
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, p: 1, pt: 5 }}>
          <Link href="https://apps.apple.com/app/idXXXXXXXX" target="_blank" rel="noopener noreferrer">
            <img src={appStoreImage} alt="App Store" style={{ width: '120px', borderRadius: '10px' }} />
          </Link>
          <Link href="https://play.google.com/store/apps/details?id=XXXXXXXX" target="_blank" rel="noopener noreferrer">
            <img src={playStoreImage} alt="Play Store" style={{ width: '120px', borderRadius: '10px' }} />
          </Link>
        </Box>
      </Drawer>

      <Container style={{ marginTop: 64, display: 'flex', padding: 0, justifyContent: 'center' }}>
        <Hidden smDown>
          <Box sx={{ width: 200, marginRight: 0.5, position: "fixed", marginRight: 130 }}>
            <Box sx={{ textAlign: 'center', padding: 2 }}>
              <Typography variant="h3" style={{ fontFamily: 'Outfit', fontWeight: 'bold' }}>
                Upsy.
              </Typography>
              <Typography variant="body2" style={{ fontFamily: 'Montserrat' }}>
                Каде денес?
              </Typography>
            </Box>
            <List component="nav" style={{ position: 'relative', top: 0 }}>
              <ListItem button selected={navValue === 0} onClick={() => handleNavChange(null, 0)}>
                <ListItemIcon><HomeIcon /></ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontFamily: 'Montserrat', fontWeight: 'bold' }} primary="Дома" />
              </ListItem>
              <ListItem button selected={navValue === 1} onClick={() => handleNavChange(null, 1)}>
                <ListItemIcon><DirectionsCarIcon /></ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize:'15px'}} primary="Мои Превози" />
              </ListItem>
              <ListItem button selected={navValue === 2} onClick={() => handleNavChange(null, 2)}>
                <ListItemIcon><PlaylistAddIcon /></ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontFamily: 'Montserrat', fontWeight: 'bold' }} primary="Нов Превоз" />
              </ListItem>
              <ListItem button selected={navValue === 3} onClick={() => handleNavChange(null, 3)}>
                <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontFamily: 'Montserrat', fontWeight: 'bold' }} primary="Профил" />
              </ListItem>
            </List>
          </Box>
        </Hidden>

        <Container maxWidth="md" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 35 }}>
          <Typography variant="h4" component="h1" gutterBottom style={{ color: "black", textAlign: 'center' }} sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
            Контакт
          </Typography>
          <Paper sx={{ p: 3, borderRadius: 3, border: '1px solid #ddd', maxWidth: 600, margin: '20px auto' }}>
            <form onSubmit={handleSendEmail}>
              <TextField label="Име" variant="outlined" fullWidth margin="normal" name="name" value={formData.name} onChange={handleInputChange} sx={{ fontFamily: 'Montserrat' }} required />
              <TextField label="Презиме" variant="outlined" fullWidth margin="normal" name="surname" value={formData.surname} onChange={handleInputChange} sx={{ fontFamily: 'Montserrat' }} required />
              <TextField label="Емаил" variant="outlined" fullWidth margin="normal" name="email" type="email" value={formData.email} onChange={handleInputChange} sx={{ fontFamily: 'Montserrat' }} required />
              <TextField label="Телефонски Број" variant="outlined" fullWidth margin="normal" name="phoneNumber" value={formData.phoneNumber} onChange={handleInputChange} sx={{ fontFamily: 'Montserrat' }} required />
              <TextField label="Опис" variant="outlined" fullWidth margin="normal" multiline rows={4} name="description" value={formData.description} onChange={handleInputChange} sx={{ fontFamily: 'Montserrat' }} required />
              <Button type="submit" variant="contained" fullWidth sx={{ backgroundColor: "#F8B400", color: "black", borderRadius: 2, border: '1px solid black', mt: 2, fontFamily: 'Montserrat', fontWeight: 'bold' }}>
                Испрати
              </Button>
            </form>
          </Paper>
        </Container>
      </Container>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Footer */}
      <Hidden smDown>
      <Box sx={{ width: '100%', mt: 5 }}>
          <img src={footerImage} alt="Footer" style={{ width: '100%', position: 'absolute', bottom: '0', right:'0', left:"0" }} />
          <Box sx={{ position: 'absolute', bottom: '20px', left: '10px', display: 'flex', gap: '20px' }}>
            <Link href="/about-us" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none', paddingTop: '12px' }}>За нас</Link>
            <Link href="/terms-and-conditions" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none', paddingTop: '12px' }}>Правила и Услови</Link>
            <Link href="/privacy-policy" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none', paddingTop: '12px' }}>Политика на Приватност</Link>
            <Link href="/contact" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none', paddingTop: '12px' }}>Контакт</Link>
          </Box>
        </Box>
      </Hidden>

      {/* Mobile Bottom Navigation */}
      <Hidden smUp>
        <Box sx={{ width: '100%', position: 'fixed', bottom: 0, backgroundColor: '#fff' }}>
          <BottomNavigation value={navValue} onChange={handleNavChange} showLabels>
            <BottomNavigationAction label="Дома" icon={<HomeIcon />} />
            <BottomNavigationAction label="Мои Превози" icon={<DirectionsCarIcon />} />
            <BottomNavigationAction label="Нов Превоз" icon={<PlaylistAddIcon />} />
            <BottomNavigationAction label="Профил" icon={<AccountCircleIcon />} />
          </BottomNavigation>
        </Box>
      </Hidden>
    </div>
  );
}

export default Contact;
