import React, { useEffect, useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { db } from "./firebaseConfig";
import { collection, getDocs, query, where, doc, updateDoc, deleteDoc, Timestamp, getDoc } from "firebase/firestore";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  List,
  ListItem,
  ListItemText,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
  Hidden,
  ListItemIcon,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Snackbar,
  TextField,
  Drawer,
  Link,
  Grid,
  MenuItem
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ListIcon from "@mui/icons-material/List";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import PhoneIcon from "@mui/icons-material/Phone";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import footerImage from './footer.png';
import MenuIcon from "@mui/icons-material/Menu";
import MessageIcon from "@mui/icons-material/Message"; 
import logo from './logo.png';// Import message icon
import { Avatar } from "@mui/material";
import { Helmet } from "react-helmet";
import cities from './cities.json';


function MyRides() {
  const [rides, setRides] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [navValue, setNavValue] = useState(1);
  const [selectedRide, setSelectedRide] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [editRide, setEditRide] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();
  const [loadingAuth, setLoadingAuth] = useState(true);  // State for auth loading
  const [loadingData, setLoadingData] = useState(true);
  const [filteredFromCities, setFilteredFromCities] = useState(cities);
const [filteredToCities, setFilteredToCities] = useState(cities);
const [showFromCityDropdown, setShowFromCityDropdown] = useState(false);
const [showToCityDropdown, setShowToCityDropdown] = useState(false);
 

  useEffect(() => {
    const fetchRides = async () => {
      const user = auth.currentUser;
      if (user) {
        setLoadingAuth(false);  // Auth is ready
        const ridesRef = collection(db, "offers");
        const q = query(ridesRef, where("userId", "==", user.uid));
        const querySnapshot = await getDocs(q);

        const ridesWithDriverNames = await Promise.all(querySnapshot.docs.map(async (rideDoc) => {
          const data = rideDoc.data();
          const userDocRef = doc(db, "users", data.userId);
          const userDoc = await getDoc(userDocRef);

          let driverName = "Unknown";
          if (userDoc.exists()) {
            driverName = userDoc.data().name;
          }

          return {
            id: rideDoc.id,
            ...data,
            driverName,
            date: data.date.toDate(),
            time: data.time.toDate()
          };
        }));

        setRides(ridesWithDriverNames);
      }
      setLoadingData(false);  // Data is ready
    };

    const fetchFavorites = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const favorites = userDocSnap.data().favorites || [];
          const favoriteRides = await Promise.all(
            favorites.map(async (rideId) => {
              const rideDoc = await getDoc(doc(db, "offers", rideId));
              if (rideDoc.exists()) {
                const rideData = rideDoc.data();
                const userDoc = await getDoc(doc(db, "users", rideData.userId));
                if (userDoc.exists()) {
                  rideData.driverName = userDoc.data().name;
                } else {
                  rideData.driverName = "Unknown";
                }
                rideData.id = rideDoc.id;
                rideData.date = rideData.date.toDate();
                rideData.time = rideData.time.toDate();
                return rideData;
              }
              return null;
            })
          );
          setFavorites(favoriteRides.filter(ride => ride !== null));
        }
      }
    };

    fetchRides();
    fetchFavorites();
  }, [auth]);

  // Show loading state if authentication or data is loading
  if (loadingAuth || loadingData) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Avatar src={logo} alt="Loading" style={{ width: 100, height: 100 }} />
      </div>
    );
  }
  

  const handleNavChange = (event, newValue) => {
    setNavValue(newValue);
    if (newValue === 0) {
      navigate("/");
    } else if (newValue === 1) {
      navigate("/my-rides");
    } else if (newValue === 2) {
      navigate("/create-ride");
    } else if (newValue === 3) {
      navigate("/profile");
    } else if (newValue === 4) {
      navigate("/packets-selection");
    }
  };

  const handleEditOpen = (ride) => {
    setEditRide(ride);
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "time") {
      // Check if the value is a valid time string (HH:mm)
      const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;
      if (!timePattern.test(value)) {
        // Invalid time format, do not update the state
        return;
      }
    }
  
    // Update the editRide state
    setEditRide({ ...editRide, [name]: value });
  };
  
  
  const handleEditSave = async () => {
    try {
      // Ensure both date and time are present
      if (!editRide.date || !editRide.time) {
        throw new Error("Date or time is missing or invalid.");
      }
  
      // Create a new Date object for the date part
      const date = new Date(editRide.date);
  
      // Check if the created date is valid
      if (isNaN(date.getTime())) {
        throw new Error("Invalid date format");
      }
  
      let hours, minutes;
      // Handle different formats of time
      if (editRide.time instanceof Date) {
        hours = editRide.time.getHours();
        minutes = editRide.time.getMinutes();
      } else if (typeof editRide.time === "string" && editRide.time.includes(":")) {
        const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;
        if (!timePattern.test(editRide.time)) {
          throw new Error("Invalid time format");
        }
        [hours, minutes] = editRide.time.split(":").map(Number);
      } else {
        throw new Error("Time must be a Date object or a valid HH:mm string");
      }
  
      // Set the hours and minutes on the date object
      date.setHours(hours, minutes, 0, 0);
  
      // Adjust for time zone differences by converting to UTC
      const utcDate = new Date(
        Date.UTC(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
          date.getMinutes(),
          0
        )
      );
  
      // Validate the date again after setting time
      if (isNaN(utcDate.getTime())) {
        throw new Error("Invalid date or time after setting hours and minutes");
      }
  
      // Create Firestore Timestamp objects for date and time
      const updatedRide = {
        ...editRide,
        date: Timestamp.fromDate(utcDate),
        time: Timestamp.fromDate(utcDate),
      };
  
      // Update the document in Firestore
      const rideDoc = doc(db, "offers", editRide.id);
      await updateDoc(rideDoc, updatedRide);
  
      setRides((prevRides) =>
        prevRides.map((ride) =>
          ride.id === editRide.id ? { ...updatedRide, date: utcDate, time: utcDate } : ride
        )
      );
  
      setOpenEdit(false);
      setSnackbarMessage("Превозот е успешно ажуриран.");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Грешка при ажурирање на документот:", error);
      setSnackbarMessage("Грешка при ажурирање на превозот.");
      setSnackbarOpen(true);
    }
  };
  
  
  
  const handleDeleteOpen = (rideId, event) => {
    event.stopPropagation();
    setSelectedRide(rideId);
    setOpenDelete(true);
  };
  

  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  const handleDelete = async (rideId, event) => {
    event.stopPropagation();
    try {
      const rideDoc = doc(db, "offers", rideId);
      await deleteDoc(rideDoc);
      setRides((prevRides) => prevRides.filter((ride) => ride.id !== rideId));
      setSnackbarMessage("Огласот е избришан успешно.");
    } catch (error) {
      console.error("Грешка при бишење:", error);
      setSnackbarMessage("Грешка при бришење, обидете се повторно.");
    } finally {
      setOpenDelete(false);
      setSnackbarOpen(true);
    }
  };
  

  const handleFavoriteDelete = async (ride, event) => {
    event.stopPropagation();
    const user = auth.currentUser;
    if (!user) {
      alert("You need to be logged in to remove favorites");
      return;
    }

    const userDocRef = doc(db, "users", user.uid);
    const userDocSnap = await getDoc(userDocRef);

    if (!userDocSnap.exists()) {
      alert("User document does not exist");
      return;
    }

    let userFavorites = userDocSnap.data().favorites || [];
    if (userFavorites.includes(ride.id)) {
      userFavorites = userFavorites.filter(id => id !== ride.id);

      await updateDoc(userDocRef, { favorites: userFavorites });

      setFavorites(favorites.filter(favRide => favRide.id !== ride.id));
    }
  };

  const handleRideClick = (ride) => {
    setSelectedRide({
      ...ride,
      date: ride.date.toLocaleDateString(),
      time: ride.time.toLocaleTimeString()
    });
    setOpenDetails(true);
  };

  const handleDetailsClose = () => {
    setOpenDetails(false);
  };

  const formatDate = (timestamp) => {
    try {
      if (timestamp instanceof Timestamp) {
        return timestamp.toDate();
      } else if (timestamp.seconds !== undefined && timestamp.nanoseconds !== undefined) {
        return new Timestamp(timestamp.seconds, timestamp.nanoseconds).toDate();
      } else if (typeof timestamp === "string") {
        return new Date(timestamp);
      } else if (timestamp instanceof Date) {
        return timestamp;
      } else {
        console.error("Unknown timestamp format:", timestamp);
        return new Date(NaN);
      }
    } catch (error) {
      console.error("Failed to parse date:", timestamp, error);
      return new Date(NaN);
    }
  };
  

  const renderRideDetails = (ride) => {
    const user = getAuth().currentUser;

    return (
      <Dialog open={openDetails} onClose={handleDetailsClose} fullWidth maxWidth="sm">
        <DialogTitle>
          <Typography variant="h6" sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
            {ride.date}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body1" sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
                Од:
              </Typography>
              <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
                {ride.departureCity}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
                До:
              </Typography>
              <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
                {ride.arrivalCity}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
                Дата:
              </Typography>
              <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
                {ride.date}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
                Време:
              </Typography>
              <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
                {ride.time}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
                Возач:
              </Typography>
              <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
                {ride.driverName}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
                Цена:
              </Typography>
              <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
                {ride.price} ден.
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
                Автомобил:
              </Typography>
              <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
                {ride.carModel}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
                Слободни места:
              </Typography>
              <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
                {ride.seats}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
                Белешки:
              </Typography>
              <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
                {ride.description || "/"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>
                Телефон:
              </Typography>
              {user ? (
                <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
                  {ride.phoneNumber}
                </Typography>
              ) : (
                <Typography variant="body2" sx={{ fontFamily: 'Montserrat' }}>
                  *Најавете се за да го видете телефонскиот број{" "}
                  <Link href="/login" sx={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>
                    Најавете се
                  </Link>
                </Typography>
              )}
              {user && (
                <Hidden smUp>
                  <Button variant="contained" color="primary" startIcon={<PhoneIcon />} href={`tel:${ride.phoneNumber}`} sx={{ mt: 1 }}>
                    Call
                  </Button>
                  <Button variant="contained" color="primary" startIcon={<MessageIcon />} href={`sms:${ride.phoneNumber}`} sx={{ mt: 1, ml: 1 }}>
                    Message
                  </Button>
                </Hidden>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDetailsClose} color="primary">
            Затвори
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
<div style={{ backgroundColor: "#F8B400", minHeight: "100vh", display: 'flex', flexDirection: 'column' }}>      <AppBar position="fixed" sx={{ backgroundColor: "rgba(255, 255, 255, 0.5)", backdropFilter: "blur(10px)", paddingBottom: 1, paddingTop: 0.5 }}>
<Helmet>
        <title>Мои Превози | Upsy.mk</title>
        <meta name="description" content="Прегледај ги твоите превози и управувај со нив на Upsy.mk." />
      </Helmet>
        <Toolbar>
        <Hidden smUp>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setDrawerOpen(true)}
              sx={{ color: "black" }} // Set icon color to black
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Typography
            variant="h6"
            component={RouterLink}
            to="/"
            sx={{ color: "black", fontFamily: "Outfit", fontWeight: "bold", flexGrow: 1, textDecoration: "none" }}
          >
            Upsy.mk
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" sx={{backdropFilter:"blur(10px)"}} open={drawerOpen} onClose={() => setDrawerOpen(false)} >
        <List>
          <ListItem button onClick={() => { navigate("/about-us"); setDrawerOpen(false); }}>
            <ListItemText primary="За нас" />
          </ListItem>
          <ListItem button onClick={() => { navigate("/terms-and-conditions"); setDrawerOpen(false); }}>
            <ListItemText primary="Правила и Услови" />
          </ListItem>
          <ListItem button onClick={() => { navigate("/privacy-policy"); setDrawerOpen(false); }}>
            <ListItemText primary="Политика на Приватност" />
          </ListItem>
        </List>
      </Drawer>
      <Container style={{ marginTop: 64, display: "flex", flexGrow: 1, padding: 0, justifyContent: "center" }}>        <Hidden smDown>
      <Box sx={{ width: 200, marginRight: 0.5, position: "fixed", marginRight: 130 }}>
            <Box sx={{ textAlign: 'center', padding: 2 }}>
              <Typography variant="h3" style={{ fontFamily: 'Outfit', fontWeight: 'bold' }}>
                Upsy.
              </Typography>
              <Typography variant="body2" style={{ fontFamily: 'Montserrat' }}>
                Каде денес?
              </Typography>
            </Box>
            <List component="nav" style={{ position: 'relative', top: 0 }}>
              <ListItem button selected={navValue === 0} onClick={() => handleNavChange(null, 0)}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Дома" />
              </ListItem>
              <ListItem button selected={navValue === 1} onClick={() => handleNavChange(null, 1)}>
                <ListItemIcon>
                  <DirectionsCarIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold', fontSize:'15px'}} primary="Мои Превози" />
              </ListItem>
              <ListItem button selected={navValue === 2} onClick={() => handleNavChange(null, 2)}>
                <ListItemIcon>
                  <PlaylistAddIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Нов Превоз" />
              </ListItem>
              <ListItem button selected={navValue === 3} onClick={() => handleNavChange(null, 3)}>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Профил" />
              </ListItem>
              {/* <ListItem button selected={navValue === 4} onClick={() => handleNavChange(null, 4)}>
                <ListItemIcon>
                  <AddToPhotosIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontFamily: 'Montserrat', fontWeight:'bold'}} primary="Пакети" />
              </ListItem> */}
            </List>
          </Box>
        </Hidden>
        <Container maxWidth="md" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 35 }}>
          <Paper sx={{ p: 3, borderRadius: 3, border: "1px solid #ddd", maxWidth: 600, margin: "0 auto", "@media (max-width: 600px)": { margin: 2 } }}>
            <Typography variant="h4" component="h1" gutterBottom style={{ fontFamily: "Montserrat", fontWeight: "bold" }}>
              Мои Превози
            </Typography>
            {rides.length === 0 ? (
              <Typography variant="h6" align="center" gutterBottom fontFamily={'Montserrat'}>
                Нема мои превози.
              </Typography>
            ) : (
              <List>
                {rides.map((ride, index) => {
                  const rideDate = formatDate(ride.date);
                  const rideTime = formatDate(ride.time);
                  return (
                    <ListItem key={index} button onClick={() => handleRideClick(ride)}>
                      <ListItemText
                        primary={<Typography sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>{ride.departureCity} {'>'} {ride.arrivalCity}</Typography>}
                        secondary={
                          <span>
                            <span>{`${rideTime.getHours().toString().padStart(2, '0')}:${rideTime.getMinutes().toString().padStart(2, '0')}`}</span>
                            <br />
                            <span>{ride.driverName}</span>
                          </span>
                        }
                      />
                      <Typography variant="body2" sx={{ fontWeight: 'bold', marginLeft: 'auto' }}>
                        {ride.price} ден.
                      </Typography>
                      <IconButton onClick={(event) => { event.stopPropagation(); handleEditOpen(ride); }}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={(event) => handleDelete(ride.id, event)} sx={{ color: "red" }}>
  <DeleteIcon />
</IconButton>

                    </ListItem>
                  );
                })}
              </List>
            )}
          </Paper>
          <Paper sx={{ p: 3, borderRadius: 3, border: "1px solid #ddd", maxWidth: 600, margin: "20px auto", "@media (max-width: 600px)": { margin: 2, marginBottom:12 } }}>
            <Typography variant="h4" component="h1" gutterBottom style={{ fontFamily: "Montserrat", fontWeight: "bold" }}>
              Омилени
            </Typography>
            {favorites.length === 0 ? (
              <Typography variant="h6" align="center" gutterBottom fontFamily={'Montserrat'}>
               Нема омилени превози.
              </Typography>
            ) : (
              <List>
                {favorites.map((ride, index) => {
                  const rideDate = formatDate(ride.date);
                  const rideTime = formatDate(ride.time);
                  return (
                    <ListItem key={index} button onClick={() => handleRideClick(ride)}>
                      <ListItemText
                        primary={<Typography sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>{ride.departureCity} {'>'} {ride.arrivalCity}</Typography>}
                        secondary={
                          <span>
                            <span>{`${rideTime.getHours().toString().padStart(2, '0')}:${rideTime.getMinutes().toString().padStart(2, '0')}`}</span>
                            <br />
                            <span>{ride.driverName}</span>
                          </span>
                        }
                      />
                      <Typography variant="body2" sx={{ fontWeight: 'bold', marginLeft: 'auto' }}>
                        {ride.price} ден.
                      </Typography>
                      <IconButton onClick={(event) => handleFavoriteDelete(ride, event)} sx={{ color: "red" }}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  );
                })}
              </List>
            )}
          </Paper>
        </Container>
      </Container>
      <Hidden smUp>
        <Box sx={{ width: "100%", position: "fixed", bottom: 0, backgroundColor: "#fff" }}>
          <BottomNavigation value={navValue} onChange={handleNavChange} showLabels>
          <BottomNavigationAction label="Дома" icon={<HomeIcon />} />
            <BottomNavigationAction label="Мои Превози" icon={<DirectionsCarIcon />} />
            <BottomNavigationAction label="Нов Превоз" icon={<PlaylistAddIcon />} />
            <BottomNavigationAction label="Профил" icon={<AccountCircleIcon />} />
            {/* <BottomNavigationAction label="Пакети" icon={<AddToPhotosIcon />} /> */}
          </BottomNavigation>
        </Box>
      </Hidden>
      <Dialog open={openEdit} onClose={handleEditClose}>
  <DialogTitle sx={{ fontFamily: 'Montserrat', fontWeight: 'bold' }}>Ажурирај Превоз</DialogTitle>
  <DialogContent>
    <DialogContentText sx={{ fontFamily: 'Montserrat' }}>
      Променете ги деталите на вашиот превоз.
    </DialogContentText>
    <TextField
      margin="dense"
      label="Град на Поаѓање"
      variant="outlined"
      fullWidth
      name="departureCity"
      value={editRide.departureCity || ""}
      onChange={(e) => {
        const inputValue = e.target.value;
        setEditRide({ ...editRide, departureCity: inputValue });
        setFilteredFromCities(
          cities.filter((city) =>
            city.toLowerCase().includes(inputValue.toLowerCase())
          )
        );
        setShowFromCityDropdown(true);
      }}
      error={!filteredFromCities.includes(editRide.departureCity)}
      helperText={
        !filteredFromCities.includes(editRide.departureCity) &&
        "Мора да изберете град од листата."
      }
      onFocus={() => setShowFromCityDropdown(true)}
      onBlur={() => setTimeout(() => setShowFromCityDropdown(false), 200)}
      sx={{ fontFamily: 'Montserrat' }}
    />
    {showFromCityDropdown && (
      <Box sx={{ maxHeight: 200, overflowY: 'auto', border: '1px solid #ddd', borderRadius: 2, marginBottom: 2 }}>
        {filteredFromCities.map((city, index) => (
          <MenuItem key={index} value={city} onClick={() => {
            setEditRide({ ...editRide, departureCity: city });
            setShowFromCityDropdown(false);
          }}>
            {city}
          </MenuItem>
        ))}
      </Box>
    )}

    {/* Arrival City Dropdown */}
    <TextField
      margin="dense"
      label="Град на Пристигнување"
      variant="outlined"
      fullWidth
      name="arrivalCity"
      value={editRide.arrivalCity || ""}
      onChange={(e) => {
        const inputValue = e.target.value;
        setEditRide({ ...editRide, arrivalCity: inputValue });
        setFilteredToCities(
          cities.filter((city) =>
            city.toLowerCase().includes(inputValue.toLowerCase())
          )
        );
        setShowToCityDropdown(true);
      }}
      error={!filteredToCities.includes(editRide.arrivalCity)}
      helperText={
        !filteredToCities.includes(editRide.arrivalCity) &&
        "Мора да изберете град од листата."
      }
      onFocus={() => setShowToCityDropdown(true)}
      onBlur={() => setTimeout(() => setShowToCityDropdown(false), 200)}
      sx={{ fontFamily: 'Montserrat' }}
    />
    {showToCityDropdown && (
      <Box sx={{ maxHeight: 200, overflowY: 'auto', border: '1px solid #ddd', borderRadius: 2, marginBottom: 2 }}>
        {filteredToCities.map((city, index) => (
          <MenuItem key={index} value={city} onClick={() => {
            setEditRide({ ...editRide, arrivalCity: city });
            setShowToCityDropdown(false);
          }}>
            {city}
          </MenuItem>
        ))}
      </Box>
    )}

    <TextField
      margin="dense"
      label="Датум"
      type="date"
      fullWidth
      name="date"
      value={editRide.date ? new Date(editRide.date).toISOString().split("T")[0] : ""}
      onChange={handleEditChange}
      InputLabelProps={{ shrink: true }}
      inputProps={{ min: new Date().toISOString().split("T")[0] }}
      sx={{ fontFamily: 'Montserrat' }}
    />
    <TextField
      margin="dense"
      label="Време"
      type="time"
      fullWidth
      name="time"
      value={
        editRide.time && !isNaN(new Date(editRide.time).getTime())
          ? new Date(editRide.time).toISOString().split("T")[1].substr(0, 5)
          : ""
      }
            onChange={handleEditChange}
      InputLabelProps={{ shrink: true }}
      sx={{ fontFamily: 'Montserrat' }}
    />
    <TextField
      margin="dense"
      label="Број на Места"
      type="number"
      fullWidth
      name="seats"
      value={editRide.seats || ""}
      onChange={handleEditChange}
      sx={{ fontFamily: 'Montserrat' }}
    />
    <TextField
      margin="dense"
      label="Цена"
      type="number"
      fullWidth
      name="price"
      value={editRide.price || ""}
      onChange={handleEditChange}
      sx={{ fontFamily: 'Montserrat' }}
    />
    <TextField
      margin="dense"
      label="Опис"
      type="text"
      fullWidth
      name="description"
      value={editRide.description || ""}
      onChange={handleEditChange}
      sx={{ fontFamily: 'Montserrat' }}
    />
  </DialogContent>
  <DialogActions>
    <Button onClick={handleEditClose} color="primary" sx={{ fontFamily: 'Montserrat' }}>
      Откажи
    </Button>
    <Button onClick={handleEditSave} color="primary" sx={{ fontFamily: 'Montserrat' }}>
      Зачувај
    </Button>
  </DialogActions>
</Dialog>

      {selectedRide && renderRideDetails(selectedRide)}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
     <Hidden smDown>
     <Box
  sx={{
    position: 'relative',
    width: '100%',
    height: 'auto', // Adjust height to fit content, not fixed height
    mt: 'auto', // Pushes the footer down if there is content above it
    padding: 0, // Ensure no padding
    margin: 0, // Ensure no margin
    display: 'flex', // Flexbox to help align the content
    justifyContent: 'center' // Centers content in the middle horizontally
  }}
>
  <img src={footerImage} alt="Footer" style={{ width: '100%', position: 'relative', bottom: 0 }} />
  <Box
    sx={{
      position: 'absolute',
      bottom: '20px',
      left: '10px',
      display: 'flex',
      gap: '20px',
    }}
  >
    <Link href="/about-us" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none' }}>За нас</Link>
    <Link href="/terms-and-conditions" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none' }}>Правила и Услови</Link>
    <Link href="/privacy-policy" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none' }}>Политика на Приватност</Link>
    <Link href="/contact" sx={{ fontFamily: 'Montserrat', color: 'black', textDecoration: 'none'}}>Контакт</Link>
  </Box>
</Box>

  </Hidden>
    </div>
  );
}

export default MyRides;
